import Glide from "@glidejs/glide";

(function(){

    // AGE GATE
    if (sessionStorage.getItem('eichhof_agegate') !== 'true') {
        //sessionStorage.setItem('advertOnce','true');
        document.getElementById('agegate').classList.add('active');
        document.querySelector('.overlay-verify').classList.add('active');
        document.body.classList.add('no-scroll');
    }else{
        document.getElementById('agegate').classList.remove('active');
        document.querySelector('.overlay-verify').classList.remove('active');
        document.body.classList.remove('no-scroll');
    }

    document.addEventListener('click', function (event) {
        // If the clicked element doesn't have the right selector, bail
        if (event.target.matches('.accept_ag')) {
            event.preventDefault();

            // Log the clicked element in the console
            console.log(event.target);

            sessionStorage.setItem('eichhof_agegate','true');
            document.body.classList.remove('no-scroll');
            document.getElementById('agegate').classList.remove('active');
            document.querySelector('.overlay-verify').classList.remove('active');
        } else if(event.target.matches('.disallow_ag')) {
            window.location = 'https://google.com'
        } else if(event.target.matches('.menu')) {

            if(event.target.matches('.active:not(.back)')) {
                event.target.classList.add('back')
                document.querySelector('nav').classList.remove('active')
            } else if (event.target.matches('.active.back')) {
                event.target.classList.remove('back')
                document.querySelector('nav').classList.add('active')
            } else {
                event.target.classList.add('active')
                document.querySelector('nav').classList.add('active')
            }
        } else {
            return;
        }

    }, false);




    var doc = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;

    var header = document.getElementById('header');

    //BEER INDEX ONLY
    var section = document.querySelectorAll(".beer-grouped");
    var sections = {};
    var i = 0;

    Array.prototype.forEach.call(section, function(e) {
        sections[e.id] = e.offsetTop - window.outerHeight / 2 ;
    });

    var checkScrollBeers = function() {
        for (i in sections) {
            if (sections[i] <= curScroll) {
                document.querySelectorAll('.site-nav').forEach(function(el) {
                    el.classList.remove('active')
                })
                document.querySelector('a.site-nav[href*=' + i + ']').classList.add('active')
            }
        }
    };
    window.addEventListener('scroll', checkScrollBeers);

    if(document.querySelector('body.single-beer .single-beer-intro p.legend')) {
        let beerDetailIngredientToggle = document.querySelector('.single-beer-intro p.legend');
        let beerDetailIngredientContent = document.querySelector('.single-beer-intro .ingredients-popup');

        beerDetailIngredientToggle.addEventListener('click', function () {
            beerDetailIngredientContent.classList.toggle('open');
        });
    }

    //STICKY SIDEBAR
    /*if(document.getElementById('side-nav')) {
        var observer = new IntersectionObserver(function(entries) {
            if(entries[0].isIntersecting === true) {
                document.getElementById('side-nav').classList.remove('stick');
            } else {
                document.getElementById('side-nav').classList.add('stick');
            }
        }, { threshold: [0] });

        observer.observe(document.querySelector("#rel-entries"));
    }*/

    //SINGLE ENTRY (INDEX)

    var pbsection = document.querySelectorAll(".pb-section");
    var pbsections = {};
    var j = 0;

    var sidebar = document.querySelector('ul.side-nav-content');
    console.log(sidebar);

    Array.prototype.forEach.call(pbsection, function(e) {
        pbsections[e.id] = e.offsetTop - window.outerHeight / 2 ;

        if(e.querySelector('h3')) {
            var newListItem = document.createElement("li");
            var newLinkItem = document.createElement("a");
            newLinkItem.title = e.querySelector('h3').innerHTML;
            newLinkItem.innerHTML = e.querySelector('h3').innerHTML;
            newLinkItem.href = '#' + e.id;
            newLinkItem.className = 'site-nav';
            newListItem.appendChild(newLinkItem)
            sidebar.appendChild(newListItem)
        }
    });

    var checkScrollContent = function() {
        for (j in pbsections) {
            if (pbsections[j] <= curScroll) {
                document.querySelectorAll('.site-nav').forEach(function(el) {
                    el.classList.remove('active')
                })

                if(document.querySelector('a.site-nav[href*=' + j + ']')) {
                    document.querySelector('a.site-nav[href*=' + j + ']').classList.add('active')
                }
            }
        }
    };
    window.addEventListener('scroll', checkScrollContent);


    //HEADER SCROLL
    var checkScroll = function() {
        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
            //scrolled up
            direction = 2;
        }
        else if (curScroll < prevScroll) {
            //scrolled down
            direction = 1;
        }

        if (direction !== prevDirection) {
            toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;
    };
    var toggleHeader = function(direction, curScroll) {
        if (direction === 2 && curScroll > window.outerHeight / 3) {
            header.classList.add('hide');
            prevDirection = direction;
        }
        else if (direction === 1) {
            header.classList.remove('hide');
            prevDirection = direction;
        }
    };

    window.addEventListener('scroll', checkScroll);


    //LANG SELECTOR HANDLER
    var langDropdown = document.querySelector('.dropdown');
    var langSel = document.querySelector('.selLabel');
    langSel.addEventListener('click', function() {
        langDropdown.classList.toggle('active');
    });
})();
