// import resolveConfig from 'tailwindcss/resolveConfig'
// import tailwindConfig from '../../tailwind.config.js'

import Glide from "@glidejs/glide";

// const fullConfig = resolveConfig(tailwindConfig)

var forward = document.querySelector('.back')
var backward = document.querySelector('.next')

if(document.querySelector('.glide')) {
    var glide = new Glide('.glide', {
        type: 'slider',
        startAt: 0,
        perView: 3,
        peek: { before: 0, after: 100 },
        breakpoints: {
            650: {
                perView: 1
            },
            768: {
                perView: 2
            },
            1024: {
                perView: 3
            }
        }
    }).mount()

    forward.addEventListener('click', function () {
        glide.go('<')
    })

    backward.addEventListener('click', function () {
        glide.go('>')
    })
}
